import * as React from "react";
import { connect } from "react-redux";
import { Person as GraphQlPerson } from "@radivision/graphql/lib/ts/graphql/person";
import PageVisibility from "react-page-visibility";
import isEmpty from "lodash.isempty";
import * as Selectors from "../mixed-carousel/selectors";
import { CarouselContext } from "./context";
import { Container, Item, Thumbnail, ItemTitle, Header, Slider } from "./elements";
import Logo from "./show-logo";
import Description from "./show-description";
import Campaigns from "./show-campaigns";
import Badges from "./show-badges";
import Title from "./show-title";
import { OpenDetailsPanelPayload, OpenDetailsPanelAction } from "../../../redux/details-panel/types";
import { getCleanHtmlId } from "../../../utilities/general";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import * as Actions from "../../../redux/actions";
import { Router } from "../../../utilities/router";
import { AppState } from "../../../redux";
import { RdvCarouselProps } from "../mixed-carousel/types";
import { PlayFullScreenVideoPayload, PlayFullScreenVideoAction } from "../../../redux/full-screen-video/types";
import { useWindowSize } from "../../../utilities/use-window-size";
import { GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY } from "@radivision/graphql";

import Ticker from "react-ticker";
import "./styles.scss";
import { Card } from "./card";
import { SVG } from "../../../component-configuration/svgs";
import { rotate } from "../../../utilities/rotate";

interface ComponentProps {
  displayListLogo: boolean;
}
interface MapStateProps {
  user: GraphQlPerson;
}

interface MapDispatchProps {
  openDetailsPanel: (p: OpenDetailsPanelPayload) => OpenDetailsPanelAction;
  playFullScreenVideo: (p: PlayFullScreenVideoPayload) => PlayFullScreenVideoAction;
}

export type Props = RdvCarouselProps & ComponentProps & MapDispatchProps & MapStateProps;

const Carousel = (props: Props): JSX.Element => {
  const { show } = props;
  const [pageIsVisible, setPageIsVisible] = React.useState(true);
  const windowSize = useWindowSize();
  const screenWidth = windowSize?.width || 420;
  const [isMouseOver, setMouseOver] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const _items: CarouselItem[] | undefined = show?.items as CarouselItem[];
  const items = rotate(_items, page * 6);

  const slidesToShow =
    screenWidth <= 320
      ? 2
      : screenWidth <= 480
      ? 3
      : screenWidth <= 576
      ? 3
      : screenWidth <= 768
      ? 3
      : screenWidth <= 920
      ? 4
      : screenWidth < 1200
      ? 5
      : screenWidth < 1600
      ? 6
      : 7;

  const width = (screenWidth + 120) / slidesToShow;

  const renderItem = React.useCallback(
    ({ index }: { index: number }) => {
      if (!items) return null;
      const itemIndex = (index + items.length) % items.length;
      const item: CarouselItem = items[itemIndex];

      return <Card item={item} show={show} itemIndex={itemIndex} itemWidth={width} />;
    },
    [width, show, items, page],
  );

  const onMouseEnter = React.useCallback(() => setMouseOver(true), []);
  const onMouseLeave = React.useCallback(() => setMouseOver(false), []);

  const onPageVisibilityChange = React.useCallback((isVisible) => {
    setPageIsVisible(isVisible);
  }, []);

  React.useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [pageIsVisible]);

  return (
    <CarouselContext.Provider value={props}>
      <PageVisibility onChange={onPageVisibilityChange}>
        <Container id={`${show.id}`}>
          <Header>
            <Logo />
            <Campaigns />
            <Title />
            <Description />
            <Badges />
          </Header>
          {/* <div className="next-button" onClick={() => setPage(page + 1)}>
            {SVG.chevronRight}
          </div>
          <div className="prev-button" onClick={() => setPage(page - 1)}>
            {SVG.chevronLeft}
          </div> */}
          <div
            style={{ position: "relative", width: "100vw", height: "240px" }}
            onMouseEnter={onMouseEnter}
            onMouseOut={onMouseLeave}
            onMouseLeave={onMouseLeave}
            onMouseOver={onMouseEnter}
          >
            <div className="next-button" onClick={() => setPage(page + 1)}>
              {SVG.chevronRight}
            </div>
            <div className="prev-button" onClick={() => setPage(page - 1)}>
              {SVG.chevronLeft}
            </div>
            {items?.length && (
              <Ticker move={!isMouseOver && pageIsVisible} speed={1} height="300" key={`ticker-${page}`}>
                {(index: number) => renderItem(index)}
              </Ticker>
            )}
          </div>
        </Container>
      </PageVisibility>
    </CarouselContext.Provider>
  );
};
const mapState = (state: AppState) => ({
  user: state.account.user,
});

const mapDispatch = {
  openDetailsPanel: Actions.openDetailsPanel,
  playFullScreenVideo: Actions.playFullScreenVideo,
};

export const ScrollingCarousel = connect(mapState, mapDispatch)(Carousel);

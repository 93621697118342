import * as React from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { SVG, SVGS } from "../../../component-configuration/svgs";
import { CarouselItem } from "../../../component-configuration/carousel-item";
import ProgressiveImage from "react-progressive-image";
import { GRAPHQL_TYPE_PERSON, Person, GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_PITCH_VIDEO_STORY } from "@radivision/graphql";
import { PreviewKind } from "@radivision/graphql/lib/ts/graphql/preview-type";
import YouTube from "react-youtube";
import { facebookPixelTraceEvent, snowplowAnalytics, gaEvent } from "../../../utilities/general";
import { Analytics } from "../../../utilities/analytics";
import { Router } from "../../../utilities/router";
import { getProfileInfo } from "../../../utilities/get-profile-info";
import { useCarouselContext } from "./context";
import { AppState } from "../../../redux";
import AddToSavedButton from "../../page-framework/add-to-saved-button";
import isEmpty from "lodash.isempty";
import { useWindowSize } from "../../../utilities/use-window-size";
import {
  PlayFullScreenVideoPayload,
  PlayFullScreenVideoAction,
  SetPlayingFullScreenPayload,
  SetPlayingFullScreenAction,
} from "../../../redux/full-screen-video/types";
import * as Actions from "../../../redux/actions";
import { GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY } from "@radivision/graphql";
import { formatTime } from "../../../utilities/format-time";
import LazyLoad from "react-lazyload";
import { useSelector, useDispatch } from "react-redux";
import { stopVideoPlayer } from "../../../redux/youtube-player/actions";
import { useOnScreen } from "../../../utilities/use-on-screen";
import { setAppState } from "../../../redux/actions";

interface ComponentProps {
  item: CarouselItem;
  index: number;
  isVertical: boolean;
  PortraitMode: boolean;
}

interface MapStateProps {
  user: Person | null;
  muted: boolean;
}

interface MapDispatchProps {
  setPlayingFullScreen: (payload: SetPlayingFullScreenPayload) => SetPlayingFullScreenAction;
  playFullScreenVideo: (p: PlayFullScreenVideoPayload) => PlayFullScreenVideoAction;
}

export type Props = ComponentProps & MapStateProps & MapDispatchProps;

export const _SlideItem = (props: Props): JSX.Element => {
  const { item, index, PortraitMode, isVertical } = props;
  const {
    displayMode,
    show,
    retrieveOriginalIndex,
    state,
    hasDetails,
    hideItemTitle,
    itemPreviewType,
    prevBtn,
    loadDefaultImage,
    youtubeHoverVideoOpt,
    openDetailsPanel,
    mouseLeave,
    mouseEnter,
    onYouTubeVideoEnds,
    playItemFullVideo,
    showItemTypeIcon,
  } = useCarouselContext();

  const carouselRef = React.useRef(null);
  const isVerticalOnScreen = useOnScreen(carouselRef);
  const isFirstSlide = index === 0;

  const dispatch = useDispatch();
  const { width: screenWidth } = useWindowSize();
  const isSmallScreen = screenWidth <= 480;
  const isCarouselDisplayMode: boolean = !displayMode || displayMode === "Carousel";

  if (item.type === "Nominate") return null;
  const itemOrginalIndex: number = retrieveOriginalIndex(item);
  let sliderLastItemIndex: number = state.currentSlideFirstItemIndex + state.itemsToShow - 1;
  if (state.currentSlideFirstItemIndex + state.itemsToShow > state.show.items.length) {
    sliderLastItemIndex = state.currentSlideFirstItemIndex + state.itemsToShow - 1 - state.show.items.length;
  }

  const itemIsPersonOrEntity = item.type === GRAPHQL_TYPE_ENTITY || item.type === GRAPHQL_TYPE_PERSON;
  const isOriginalShow = item.type === "OriginalContentShow" && item.details?.items;
  const isPoster = item.type === "Poster";
  const itemHasDetails =
    hasDetails &&
    (!isEmpty(item.details?.youTubeVideo) ||
      !isEmpty(item.details?.videoId) ||
      !isEmpty(item.details?.video) ||
      !isEmpty(item.details?.trailer) ||
      isOriginalShow ||
      isPoster);
  const itemHasYoutube = item.youTubeVideo || (item.link && item.link.includes("youtu"));
  const hasSourceList = !isEmpty(item?.sourceList);
  const isRadivisionOriginals = show?.title === "Radivision Originals";
  const isPitchVideoStory = item.type === GRAPHQL_TYPE_PITCH_VIDEO_STORY;
  const isPlayable = isPitchVideoStory || (itemHasDetails && !isRadivisionOriginals);

  const openDetailsPanelOnClick = React.useCallback(() => {
    if (!itemHasDetails) {
      return;
    }

    dispatch(stopVideoPlayer());
    openDetailsPanel(item.id);

    try {
      gaEvent(
        item.episodeInfo ? item.episodeInfo[0] : "Carousels",
        "Click",
        show.title ? show.title : "JwwetNdyx0mjnd4mf2OBkA",
        true,
      );
      Analytics.userHistory({
        item: item.id,
        description: item.description,
      }).then((_requestId: string): void => {
        //   "History Recorded with id ",
        //   requestId
        // );
      });
    } catch (error) {}
  }, [item, show, itemHasDetails, openDetailsPanel]);

  const openItemLink = React.useCallback(
    (item: CarouselItem) => {
      if (itemHasDetails) {
        return;
      }
      let target: string;
      let url: string;

      if (itemIsPersonOrEntity) {
        const { profileUrl } = getProfileInfo(item);
        Router.navigateTo(profileUrl);
        return;
      }

      if (!isEmpty(item.landingPageUrl)) {
        url = item.landingPageUrl;
        target = "_self";
      } else if (!isEmpty(item.permalink)) {
        target = "_self";
      } else if (!isEmpty(item.link)) {
        url = item.link;
        target = "_blank";
      }
      if (url) {
        switch (target) {
          case "_blank":
            setTimeout(() => {
              window.open(url, target);
            }, 200);
            break;
          default:
            Router.generateRouterData(url).then((data) => {
              return Router.to(data.alias, data.qp);
            });
        }
      }
    },
    [itemHasDetails, itemIsPersonOrEntity],
  );

  const shouldPlayFirstItem = isVerticalOnScreen && isFirstSlide && isVertical;
  // React.useEffect(() => {
  //   if (shouldPlayFirstItem) {
  //     mouseEnter(0);
  //   } else {
  //     mouseLeave();
  //   }
  // }, [shouldPlayFirstItem]);

  const itemOnClick = React.useCallback(
    (event) => {
      if (itemHasDetails || isPitchVideoStory) {
        mouseLeave();
        dispatch(stopVideoPlayer());
        openDetailsPanel(item.id);
      } else {
        openItemLink(item);
      }

      const url = item.landingPageUrl ? item.landingPageUrl : item.link;

      try {
        Analytics.userHistory({
          item: item.id,
          description: item.description,
        }).then((_requestId: string): void => {
          //   "History Recorded with id ",
          //   requestId
          // );
        });

        gaEvent(
          item.episodeInfo ? item.episodeInfo[0] : "Carousels",
          "Click",
          show.title ? show.title : "WXiJWxYwkWG68gQkRGUzw",
          true,
        );
        facebookPixelTraceEvent(event, {
          category: item.id ? item.id : null,
          title: `${item.title ? item.title : "Carousel Video Played"}`,
          url: item.landingPageUrl ? item.landingPageUrl : window.location.href,
        });
        snowplowAnalytics()
          .then((r) => {
            if (!url) {
              r.trackPlayVideo({
                category: item.id ? item.id : null,
                label: item.title ? item.title : "Carousel Video Played",
                property: item.link,
              });
            } else {
              r.trackClick({
                category: item.id ? item.id : null,
                label: item.title ? item.title : "Carousel Click",
                property: item.link,
              });
            }
          })
          .catch(() => console.debug("SnowPlow not initialized."));
      } catch (error) {}
    },
    [item, show, hasDetails, itemHasDetails, mouseLeave, openDetailsPanel, openItemLink, isPitchVideoStory],
  );

  const EXCLUDED_TYPES: string[] = [GRAPHQL_TYPE_PERSON, GRAPHQL_TYPE_ENTITY, GRAPHQL_TYPE_ORIGINAL_CONTENT_STORY];
  const DISPLAY_ICON = showItemTypeIcon && showItemTypeIcon === true && !EXCLUDED_TYPES.includes(item.type);
  const duration = formatTime(item?.timeLabel || item?.timeRequiredInSeconds);

  // React.useEffect(() => {
  //   const checkMute = async () => {
  //     // if (!shouldPlayFirstItem) return null;
  //     const isMuted = await videoRef?.current?.internalPlayer?.isMuted();
  //     if (isMuted === false && muted) {
  //       setAppState({ muted: false });
  //     }
  //   };
  //   const checkMuteInterval = setInterval(checkMute, 1000);
  //   return () => clearInterval(checkMuteInterval);
  // }, [muted, videoRef, setAppState, shouldPlayFirstItem]);

  return (
    <div
      className={`rdv-slide${
        (isCarouselDisplayMode && itemOrginalIndex === state.currentSlideFirstItemIndex) ||
        (!isCarouselDisplayMode && itemOrginalIndex % state.itemsToShow === 0)
          ? " first"
          : (isCarouselDisplayMode && itemOrginalIndex === sliderLastItemIndex) ||
            (!isCarouselDisplayMode && itemOrginalIndex % state.itemsToShow === state.itemsToShow - 1)
          ? " last"
          : ""
      } ${itemPreviewType} items-${state.itemsToShow}${
        item.type === GRAPHQL_TYPE_ENTITY || item.type === GRAPHQL_TYPE_PERSON ? " ecosys" : ""
      } ${itemHasDetails ? " has-details" : ""}`}
    >
      <div className={`rdv-slide-content`} onClick={isSmallScreen && itemHasDetails ? openDetailsPanelOnClick : $.noop}>
        <div
          className={`rdv-slide-img${
            !show || !show.revision || !show.revision.length ? " img-placeholder loading-gradient" : ""
          } ${isVertical ? "vertical-thumbnail" : ""}`}
        >
          {duration && !PortraitMode && (
            <div className="overlay-item-meta">
              <span className="duration">{duration}</span>
            </div>
          )}
          {item.isNew ? <span className="new-episode"> New Episode </span> : null}
          {!isCarouselDisplayMode || prevBtn.hasClass("hidden") ? (
            <LazyLoad offset={200} scroll={true}>
              <ProgressiveImage
                src={
                  item.previewImageUrl
                    ? item.previewImageUrl.requestedResolutionUrl !== undefined &&
                      item.previewImageUrl.requestedResolutionUrl !== null
                      ? item.previewImageUrl.requestedResolutionUrl
                      : item.previewImageUrl.screenResolutionUrl
                    : undefined
                }
                placeholder={item.previewImageUrl ? item.previewImageUrl.placeHolderUrl : undefined}
              >
                {(src: any, _LOADING: any, _srcSetData: any) => (
                  <img
                    loading="lazy"
                    alt={`${item.title ? item.title : ""}`}
                    src={src}
                    onError={(event) => {
                      loadDefaultImage(event.target, item.type);
                    }}
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          ) : (
            <LazyLoad offset={200} scroll={true}>
              <img
                loading="lazy"
                src={
                  item.previewImageUrl
                    ? item.previewImageUrl.requestedResolutionUrl !== undefined &&
                      item.previewImageUrl.requestedResolutionUrl !== null
                      ? item.previewImageUrl.requestedResolutionUrl
                      : item.previewImageUrl.screenResolutionUrl
                    : undefined
                }
                alt={`${item.title ? item.title : ""}`}
                onError={(event) => {
                  loadDefaultImage(event.target, item.type);
                }}
              />
            </LazyLoad>
          )}
        </div>
        <div
          className={`${state.showPanel ? "rdv-slide-details hide-details" : "rdv-slide-details"} ${
            PortraitMode ? "d-none" : ""
          }`}
          onClick={
            isOriginalShow && item?.landingPageUrl
              ? () => {
                  Router.generateRouterData(item?.landingPageUrl).then((data) => {
                    return Router.to(data.alias, data.qp);
                  });
                }
              : itemOnClick
          }
        >
          {DISPLAY_ICON ? <span className=" story-icon">{SVGS[item.type]}</span> : null}

          {!hideItemTitle || !isCarouselDisplayMode ? (
            <h6
              className={`text-uppercase rdv-slide-title multiline-ellipsis
              ${DISPLAY_ICON ? "margin-left" : ""}
              ${itemPreviewType === PreviewKind.CIRCLE ? "text-center extra-space" : ""}`}
            >
              {item.title}
            </h6>
          ) : null}
        </div>
      </div>
      {(!isSmallScreen || !itemHasDetails) &&
      (!isCarouselDisplayMode ||
        (itemOrginalIndex >= state.currentSlideFirstItemIndex &&
          itemOrginalIndex < state.currentSlideFirstItemIndex + state.itemsToShow) ||
        (state.currentSlideFirstItemIndex + state.itemsToShow > state.show.items.length &&
          itemOrginalIndex <= sliderLastItemIndex)) ? (
        <div className={`rdv-slide-content rdv-slide-hover`}>
          <div
            ref={carouselRef}
            className={`rdv-slide-img ${isVertical ? "vertical-thumbnail" : ""}`}
            onMouseEnter={() => (state.showPanel ? null : mouseEnter(index))}
            onMouseLeave={() => (state.showPanel ? null : mouseLeave())}
          >
            {item.isNew ? <span className="new-episode"> New Episode </span> : null}
            <LazyLoad offset={200} scroll={true}>
              <ProgressiveImage
                src={
                  item.previewImageUrl
                    ? item.previewImageUrl.requestedResolutionUrl !== undefined &&
                      item.previewImageUrl.requestedResolutionUrl !== null
                      ? item.previewImageUrl.requestedResolutionUrl
                      : item.previewImageUrl.screenResolutionUrl
                    : undefined
                }
                placeholder={item.previewImageUrl ? item.previewImageUrl.placeHolderUrl : undefined}
              >
                {(src: any, _LOADING: any, _srcSetData: any) => (
                  <img
                    loading="lazy"
                    alt={`${item.title ? item.title : ""}`}
                    src={src}
                    onError={(event) => {
                      loadDefaultImage(event.target, item.type);
                    }}
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            {itemHasYoutube && state.hoverPlay === index ? (
              <div className="youtube-on-hover">
                <YouTube
                  videoId={state.videoId}
                  opts={youtubeHoverVideoOpt}
                  className="youtube-on-hover"
                  onEnd={onYouTubeVideoEnds}
                />
              </div>
            ) : null}
            <div
              className={`slide-img-rollover
									${!hasDetails && !item.details && !item.video ? " no-rollover" : ""}`}
            >
              <div
                className="details-drawer-overlay"
                onClick={
                  isOriginalShow && item?.landingPageUrl
                    ? () => {
                        Router.generateRouterData(item?.landingPageUrl).then((data) => {
                          return Router.to(data.alias, data.qp);
                        });
                      }
                    : itemOnClick
                }
              />
            </div>
          </div>
          {!isRadivisionOriginals && !isVertical ? (
            <div className="slide-item-actions">
              {isPlayable ? (
                <span
                  className="play-btn"
                  onClick={() => {
                    playItemFullVideo(index);
                  }}
                >
                  {SVG.play}
                </span>
              ) : null}

              <AddToSavedButton item={item} />

              <span
                onClick={
                  isOriginalShow && item?.landingPageUrl
                    ? () => {
                        Router.generateRouterData(item?.landingPageUrl).then((data) => {
                          return Router.to(data.alias, data.qp);
                        });
                      }
                    : itemOnClick
                }
                className="has-tooltip"
              >
                {hasSourceList || isPlayable
                  ? SVG.chevronDown
                  : itemIsPersonOrEntity
                  ? SVG.externalLinkAlt
                  : !isEmpty(item?.link)
                  ? SVG.externalLinkAlt
                  : SVG.chevronDown}
                <div className="tooltip">
                  {hasSourceList ? "More episodes" : itemIsPersonOrEntity ? "Visit Profile" : "Learn More"}
                </div>
              </span>
            </div>
          ) : (
            <></>
          )}
          {/* {!isVertical ? (
            <div
              className={state.showPanel ? "rdv-slide-details hide-details" : "rdv-slide-details"}
              onClick={
                isOriginalShow && item?.landingPageUrl
                  ? () => {
                      Router.generateRouterData(item?.landingPageUrl).then((data) => {
                        return Router.to(data.alias, data.qp);
                      });
                    }
                  : itemOnClick
              }
            >
              <h6
                className={`text-uppercase rdv-slide-title multiline-ellipsis ${
                  itemPreviewType === PreviewKind.CIRCLE ? "text-center extra-space" : ""
                }`}
              >
                {item.title}
              </h6>
              {item.type === GRAPHQL_TYPE_PERSON ? <span>{item.summary}</span> : null}
            </div>
          ) : (
            <></>
          )} */}
        </div>
      ) : null}
    </div>
  );
};

const mapState = (state: AppState) => ({
  user: state.account.user,
  muted: state.app.muted,
});

const mapDispatch = {
  playFullScreenVideo: Actions.playFullScreenVideo,
  setPlayingFullScreen: Actions.setPlayingFullScreen,
};
export const SlideItem = connect(mapState, mapDispatch)(_SlideItem);

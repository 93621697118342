import { FixedList } from "@radivision/graphql/lib/ts/graphql/fixed-list";
import { Person } from "@radivision/graphql/lib/ts/graphql/person";
import { HtmlDocumentPanel as GraphQlHtmlDocumentPanel } from "@radivision/graphql/lib/ts/graphql/html-document-panel";
import $ from "jquery";
import React from "react";
import { connect } from "react-redux";
import { REACT_USER_CONTEXT } from "../../contexts/react-user-context";
import { CookieConsent } from "../page-framework/cookie-consent";
import { ScrollToTopButton } from "../page-framework/scroll-to-top-button";
import { MarketingOverlayPanel } from "../panels/marketing-overlay-panel";
import DynamicComponent from "./dynamic-component";
import isEmpty from "lodash.isempty";
import { AppState } from "../../redux";
import * as Actions from "../../redux/actions";
import * as Selectors from "./selectors";
import {
  UpdateUserPayload,
  UpdateUserAction,
  UpdateSavedItemsPayload,
  UpdateSavedItemsAction,
  FetchSavedItemsAction,
  FetchSavedItemsPayload,
  FetchCurrentUserAction,
} from "../../redux/account/types";
import { PrefetchHtmlPagesAction } from "../../redux/cache/types";
import { StructureUtilities } from "../../utilities/structure-utilities";
import { Navbar } from "../page-framework/nav-bar";
import { isIframe } from "../../utilities/general";
import { AUTH_CHOOSE_ACCOUNT_TYPE, AUTHENTICATION_MODAL_ID } from "../../redux/authentication/constants";
import { SetAuthStatePayload, SetAuthStateAction } from "../../redux/authentication/types";
import YoutubePlayer from "../page-framework/youtube-player";

export interface HtmlPageProps {
  cacheKey: string;
  queryParameters?: string[];
}

interface MapDispatchProps {
  updateUser: (p: UpdateUserPayload) => UpdateUserAction;
  updateSavedItems: (p: UpdateSavedItemsPayload) => UpdateSavedItemsAction;
  fetchSavedItems: (p: FetchSavedItemsPayload) => FetchSavedItemsAction;
  fetchCurrentUser: () => FetchCurrentUserAction;
  setAuthState: (payload: SetAuthStatePayload) => SetAuthStateAction;
  prefetchHtmlPages: () => PrefetchHtmlPagesAction;
}

interface MapStateProps {
  user: Person;
  savedItems: FixedList;
  htmlDocument: any;
  isFetchingCurrentUser: boolean;
  detailsPanelIsOpened: boolean;
}

type Props = HtmlPageProps & MapDispatchProps & MapStateProps;

const HtmlPage = (props: Props) => {
  const {
    htmlDocument,
    updateUser,
    fetchSavedItems,
    fetchCurrentUser,
    setAuthState,
    isFetchingCurrentUser,
    detailsPanelIsOpened,
    savedItems,
    cacheKey,
    user,
  } = props;

  const [hasCalledFetchCurrentUser, setCalledFetchCurrentUser] = React.useState(false);

  React.useEffect(() => {
    if (!isEmpty(user) && !isEmpty(user.myList)) {
      fetchSavedItems({ listId: user.myList as string });
    }
  }, [fetchSavedItems, user]);

  React.useEffect(() => {
    const userIsNotFetchedYet = isEmpty(user) && !hasCalledFetchCurrentUser && !isFetchingCurrentUser;
    if (userIsNotFetchedYet) {
      fetchCurrentUser();
      setCalledFetchCurrentUser(true);
    }
  }, [user, fetchCurrentUser, isFetchingCurrentUser, hasCalledFetchCurrentUser]);

  React.useEffect(() => {
    if (user && isEmpty(user.accountType?.kind)) {
      setAuthState({ step: AUTH_CHOOSE_ACCOUNT_TYPE });
      $(`#${AUTHENTICATION_MODAL_ID}`).modal("show");
    }
  }, [user, setAuthState]);

  React.useEffect(() => {
    if (cacheKey) {
      window.scrollTo({
        top: 0,
      });
      const backdrop = $(".modal-backdrop");
      const allModalsAreClosed = $(".modal.show").length === 0;
      if (backdrop && allModalsAreClosed) backdrop?.remove();
      if (!detailsPanelIsOpened && allModalsAreClosed) {
        $("body").addClass("allow-scroll");
      }
    }
  }, [cacheKey, detailsPanelIsOpened]);

  if (!htmlDocument) {
    return <div />;
  }

  const panels = htmlDocument?.urlRouteByAlias?.node?.panels;
  const nodeId = htmlDocument?.urlRouteByAlias?.node?.id;
  const hasErrors = htmlDocument?.urlRouteByAlias?.hasErrors;

  if (hasErrors) {
    return <div />;
  }

  console.log("panels => ", panels);

  return (
    <div className="main-container">
      {false && !isIframe() ? (
        <>
          <MarketingOverlayPanel />
          <CookieConsent />
        </>
      ) : null}
      <ScrollToTopButton />
      <REACT_USER_CONTEXT.Provider
        value={{
          user,
          userSavedItems: savedItems,
          updateUser: (user: Person) => {
            updateUser({ user });
            return user;
          },
          updateSavedItemsList: (list: FixedList) => this.props.updateSavedItems({ savedItems: list }),
        }}
      >
        {isEmpty(panels) && (
          <div className="main-container">
            <Navbar transparent={true} />
          </div>
        )}

        {panels &&
          panels.map((panel: GraphQlHtmlDocumentPanel, index: number) => {
            const cprops = StructureUtilities.getComponentUtilities(panel.component.properties, panel.properties);
            const key = `${nodeId}-${panel.component.key}-${JSON.stringify(cprops)}-${index}`;
            return <DynamicComponent key={key} component={panel.component} properties={panel.properties} />;
          })}
      </REACT_USER_CONTEXT.Provider>
      <YoutubePlayer />
    </div>
  );
};

const mapState = (state: AppState, props: HtmlPageProps) => ({
  user: state.account.user,
  savedItems: state.account.savedItems,
  htmlDocument: Selectors.getMainPage(state, props),
  isFetchingCurrentUser: state.account.isFetchingCurrentUser,
  detailsPanelIsOpened: state.detailsPanel.item !== null,
});

const mapDispatch = {
  updateUser: Actions.updateUser,
  updateSavedItems: Actions.updateSavedItems,
  fetchSavedItems: Actions.fetchSavedItems,
  prefetchHtmlPages: Actions.prefetchHtmlPages,
  fetchCurrentUser: Actions.fetchCurrentUser,
  setAuthState: Actions.setAuthState,
};

const ConnectedHtmlPage = connect(mapState, mapDispatch)(HtmlPage);

export default ConnectedHtmlPage;
